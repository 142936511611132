/* eslint-disable no-restricted-imports */
'use client';
import useAccountPopoverStore from '~/client/store/useAccountPopoverStore';
import Link from '@/client/components/Link';
import classNames from '@/external/classNames';
import Button from '@/external/components/Button';

type Props = { type: 'login' | 'signup'; site: Site };

const SignUpPopoverButton: FC<Props> = ({ children, type, site }) => {
  const { showPopover } = useAccountPopoverStore();
  const variant = type === 'login' ? 'secondary' : undefined;
  const className =
    type === 'login' ? 'sm:shadow-none sm:hover:shadow-none' : '';
  const isSportsbet = site === 'sportsbet';
  const sportsBetRedirect = type === 'login' ? '/id/login' : '/id/signup';

  if (isSportsbet) {
    return (
      <Button
        fullWidth
        variant={variant}
        className={classNames('font-normal', className)}
      >
        <Link href={sportsBetRedirect}>{children}</Link>
      </Button>
    );
  }

  return (
    <Button
      fullWidth
      variant={variant}
      className={classNames('font-normal', className)}
      onClick={() => showPopover(type)}
    >
      {children}
    </Button>
  );
};

export default SignUpPopoverButton;
