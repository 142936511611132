'use client';
import { useRouter } from 'next/navigation';
import ArrowsLeft from '@/external/icons/ArrowsLeft';

type Props = { href?: string };

const BackButton: FC<Props> = ({ href }) => {
  const router = useRouter();
  const handleClick = href ? () => router.push(href) : () => router.back();
  return (
    <button onClick={handleClick}>
      <ArrowsLeft className="text-moon-32 text-trunks" />
    </button>
  );
};

export default BackButton;
